<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
            <el-breadcrumb-item>行业供求市场</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 概览 -->
            <section class="section1">
                <div class="dataOverview clearfix">
                    <div class="left-box fl">
                        <div class="top clearfix">
                            <div class="title fl">园区供需数量概览</div>
                        </div>
                        <div class="data-box clearfix">
                            <div class="item fl">
                                <div class="number">6920</div>
                                <div class="text">需求数量</div>
                            </div>
                            <div class="item fl">
                                <div class="number">6920</div>
                                <div class="text">供应数量</div>
                            </div>
                            <div class="item fl">
                                <div class="number">87%</div>
                                <div class="text">供求解决率</div>
                            </div>
                        </div>
                    </div>
                    <div class="right-box fl">
                        <div class="top clearfix">
                            <div class="title fl">平台公告</div>
                            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt=""
                                class="img fr toMore" @click="go('demandList')">
                        </div>
                        <div class="list ">
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">浙江省复工复产企业供求对接平台正式开通</div>
                                <div>2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">浙江省复工复产企业供求对接平台正式开通</div>
                                <div>2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">浙江省复工复产企业供求对接平台正式开通</div>
                                <div>2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">浙江省复工复产企业供求对接平台正式开通</div>
                                <div>2020-12-12</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 物资需求 -->
            <section class="section2">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">物资需求</div>
                        <router-link to="/SupplyandDemand/demandList">
                            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt=""
                            class="img fr toMore">
                        </router-link>
                    </div>
                    <div class="item-box fl" @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                电机滑环
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <font style="color: #666;">分类：</font>电子器件
                            </p>
                            <p>
                                <font style="color: #666;">需求型号：</font>YZR3-132M2-63.7KW
                            </p>
                        </div>
                        <div class="item-bottom">
                            温州市博弘电力有限公司
                            <span class="time fr">2020-12-31截止</span>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 物资供应 -->
            <section class="section3">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">物资供应</div>
                        <router-link to="/SupplyandDemand/demandList">
                            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt=""
                            class="img fr toMore">
                        </router-link>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>
                    <div class="item-box fl"  @click="go('demandDetails')">
                        <div class="item-top">
                            <div class="item-title">
                                KN95口罩机
                            </div>
                            <div class="top-bg"></div>

                        </div>
                        <div class="item-center">
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">分类：</font>生产设备
                                </span>
                                <font style="color: #666;">供应数量：</font>3台
                            </p>
                            <p>
                                <span style="display: inline-block;width: 55%;">
                                    <font style="color: #666;">供应规格：</font>全自动机
                                </span>
                                <font style="color: #666;">供应单价：</font>面议
                            </p>
                        </div>
                        <div class="item-bottom tc">
                            温州市博弘电力有限公司
                        </div>
                    </div>

                </div>
            </section>
            <!-- 公告公示 -->
            <section class="section4" style="margin-bottom: 0;">
                <div class="postView clearfix">
                    <div class="post-box fl" style="margin-right: 16px;">
                        <div class="top clearfix">
                            <div class="title fl">招标公告</div>
                            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt=""
                                class="img fr toMore">
                        </div>
                        <div class="list">
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box fl">
                        <div class="top clearfix">
                            <div class="title fl">中标公告</div>
                            <img src="../../assets/img/businessintelligentpush/box_icon_more@3x.png" alt=""
                                class="img fr toMore">
                        </div>
                        <div class="list">
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                            <div class="list-item fl-c-b">
                                <div class="fl-1 text-over" style="color: #000000;">发电公司3号机组机炉外管更换询价采购结果公告</div>
                                <div >2020-12-12</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    // 
    export default {
        name: 'SupplyandDemand',
        data() {
            return {

            };
        },
        methods: {
            go(name, id) {
                if (id) {
                    this.$router.push({ name, params: { id } });
                } else {
                    this.$router.push({ name });
                }
            },

        },
        mounted() {

        },
        created() {

        },
    };
</script>
<style lang='scss' scoped>
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

        .concent {
            .section1 {
                height: 242px;
            }

            .section2 {
                height: 486px;
            }

            .section3 {
                height: 486px;
            }

            .section4 {
                height: 242px;
            }
        }

    }

    


    .dataOverview .left-box {
        width: calc((100% - 16px) * 0.6);
        padding: 16px 20px 24px;
        box-sizing: border-box;
        background-color: #fff;
        margin-right: 16px;
    }

    .dataOverview .left-box .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 26px;
    }

    .data-box .item {
        height: 118px;
        margin-right: 27px;
        width: calc((100% - 54px) / 3);
        border-radius: 4px;
        text-align: center;
    }

    .data-box .item:nth-of-type(1) {
        background: url(../../assets/img/businessintelligentpush/hygqsc_topimg1.png) top left no-repeat;
        background-color: #FFF0E9;
    }

    .data-box .item:nth-of-type(2) {
        background: url(../../assets/img/businessintelligentpush/hygqsc_topimg2.png) top left no-repeat;
        background-color: #FFE9E9;
    }

    .data-box .item:nth-last-of-type(1) {
        margin-right: 0;
        background: url(../../assets/img/businessintelligentpush/hygqsc_topimg3.png) top left no-repeat;
        background-color: #F7E9FF;
    }

    .data-box .item .number {
        font-size: 40px;
        color: #000000;
        line-height: 56px;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .data-box .item .text {
        font-size: 14px;
        color: #333333;
        line-height: 20px;
    }


    .dataOverview .right-box {
        width: calc((100% - 16px) * 0.4);
        padding: 16px 20px 24px;
        box-sizing: border-box;
        background-color: #fff;
    }

    .dataOverview .right-box .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 15px;
    }


    .dataOverview .right-box .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #F6FAFF 0%, #E9F4FF 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .chartView .item-box:nth-child(3n+1) {
        margin-right: 0;
    }

    .chartView .item-box:nth-child(n+4) {
        margin-bottom: 0;
    }

    .chartView .item-box .item-top {
        border-top: 2px solid #4393F8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393F8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }

    .chartView .item-box .item-center {
        padding: 0 25px;
    }

    .chartView .item-box .item-center p {
        line-height: 28px;
    }

    .chartView .item-box .item-bottom {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-top: 22px;
        background: #CEE4FF;
        border-radius: 0px 0px 4px 4px;
    }

    .chartView .top .time {
        font-size: 14px;
        color: #666666;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
    }

    .chartView .top .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393F8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }



    .postView .post-box {
        width: calc((100% - 16px) * 0.5);
        padding: 16px 20px 24px;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 36px;
    }

    .postView .post-box .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 15px;
    }


    .postView .post-box .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .postView .post-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .postView .post-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }
</style>